@font-face {
	font-family: 'Akzidenz-Grotesk BQ';
	src: url('../font/AkzidenzGroteskBQ-Reg.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-Reg.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akzidenz-Grotesk BQ Super';
	src: url('../font/AkzidenzGroteskBQ-Super.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-Super.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akzidenz-Grotesk BQ';
	src: url('../font/AkzidenzGroteskBQ-Bold.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akzidenz-Grotesk BQ Extended';
	src: url('../font/AkzidenzGroteskBQ-BoldExt.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-BoldExt.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akzidenz-Grotesk BQ';
	src: url('../font/AkzidenzGroteskBQ-Light.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Akzidenz-Grotesk BQ';
	src: url('../font/AkzidenzGroteskBQ-Medium.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBQ-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}


$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-red: #f90001;

