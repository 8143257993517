@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: 'Akzidenz-Grotesk BQ', sans-serif;
	font-size: 16px;
	line-height: 1;
	overflow: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	background: url('../img/bg/pattern.svg') repeat-x center bottom;
	background-size: auto;
	color: $color-white;
	min-height: 100vh;

	@include desktop-sm-down {
		background-position: center bottom -200px;
	}

	@include phone-down {

	}
}

a {
	color: $color-red;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(115) 0 0 0;

	h1 {
		font-size: rem(55);
		font-weight: bold;
		margin: 0;
	}
}

main {
	font-size: rem(18);
	line-height: line-height(29, 24);
	padding: rem(20) 0 rem(50) 0;

	p {
		margin: 0;

		+ p {
			margin-top: rem(20);
		}
	}
}

footer {

}
